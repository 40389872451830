@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

 
  min-height:100vh;
}

.App-link {
  color: #61dafb;
}


.Main-logo {
  max-width: 100%;
  margin-bottom: 20px;
}

.Funder-logos {
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap; /* Allow logos to wrap on smaller screens */
}

.funded_by {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px;
  font-family: 'Great Vibes', cursive;
  color: antiquewhite;
  font-size: 50px; /* Adjust font size for smaller screens below */
}

.Funder-logo {
  max-width: 100%;
  /* Adjust size for smaller screens if necessary */
}

.Funder-logo3 {
  width: 50%;
  height: 50%;
  /* Adjust size for smaller screens if necessary */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .funded_by {
    font-size: 30px; /* Smaller font size on tablets and below */
    margin: 20px; /* Less margin to save space */
  }

  .Funder-logo, .Funder-logo3 {
    width: 100%; /* Full width for smaller screens to ensure visibility */
    max-width: 100%; /* Ensure logos do not exceed container width */
    height: auto; /* Maintain aspect ratio */
  }

  .Funder-logos {
    gap: 15px; /* Smaller gap to save space */
  }
}

@media (max-width: 480px) {
  .funded_by {
    font-size: 20px; /* Even smaller font size for mobile devices */
  }

  .Funder-logo, .Funder-logo3 {
    width: 80%; /* Slightly less than full width for mobile to create margins */
    margin: 0 auto; /* Center logos horizontally */
  }

  .Funder-logos {
    gap: 10px; /* Reduce gap further for mobile */
  }
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
